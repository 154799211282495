<template>
    <div>
        <v-dialog v-model="dialog_businessdetails" :hide-overlay="isHideOverlay" content-class="businessview_fullscreendialog" scrollable persistent max-width="50%" no-click-animation transition="slide-x-transition">
            <v-card>
                <v-toolbar flat class="main_toolbar">
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5">Business Details</v-toolbar-title>
                    <!-- <v-spacer></v-spacer> -->
                    <!-- <v-btn class="mt-3 mb-3 mr-3 toolbar_rightalign_btn" @click="fn_showEditTags(displayData.BusinessID, displayData.TagIDs)" plain><v-icon>more_vert</v-icon></v-btn> -->
                    <!-- <v-btn v-if="displayData && displayData.Tags && displayData.Tags.length>0" class="mt-3 mb-3 mr-3 toolbar_rightalign_btn" @click="fn_showEditTags(displayData.BusinessID, displayData.TagIDs)" plain><v-icon>edit</v-icon> Update Tags</v-btn>
                    <v-btn v-else class="mt-3 mb-3 mr-3 toolbar_rightalign_btn" @click="fn_showAddTags(displayData.BusinessID)" plain><v-icon>add</v-icon> Add Tags</v-btn> -->
                    <v-menu offset-x offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-3 mb-3 mr-3 toolbar_rightalign_btn" icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                        </template>
                        <v-list nopadding>
                            <v-list-item v-if="displayData && displayData.Tags && displayData.Tags.length>0" @click="fn_showEditTags(displayData.BusinessID, displayData.TagIDs)">
                                <v-list-item-title>Update Tags</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else @click="fn_showAddTags(displayData.BusinessID)">
                                <v-list-item-title>Add Tags</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="displayData && displayData.Status == -1" @click="fn_showActiveBusinessConfirmation()">
                                <v-list-item-title>Activate Business</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>

                <div class="hastoolbar" full-height grey-back v-if="displayData" style="height: 100%;">
                    <v-layout ma-0 row wrap class="pt-3 pb-3 pl-3 pr-3">
                        <v-flex xs12 sm6 md6>
                            <v-list three-line style="height: 100%;">
                                <v-list-item style="padding-right:0">
                                    <v-list-item-content class="pt-2 pb-2">
                                    <v-list-item-title>{{displayData.BusinessName}}</v-list-item-title>
                                    <v-list-item-subtitle>Business ID: {{displayData.BusinessID}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>State: {{displayData.State}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="displayData.BusinessProfileNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessProfileNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>
                                    <v-list-item-subtitle v-else-if="displayData.BusinessOwnerNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessOwnerNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>

                                    <v-list-item-subtitle v-if="showBusinessMetrics">View Daily Usage: <span @click="fn_showBusinessMetrics(displayData.BusinessID)" class="label_hide_show primary-color link-item font-medium">Show</span></v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="showBusinessMetrics">View Detail Usage: <span @click="fn_showBusinessDetailMetrics(displayData.BusinessID)" class="label_hide_show primary-color link-item font-medium">Show</span></v-list-item-subtitle>
                                    <!-- <v-list-item-subtitle class="label_mobile_number" v-else-if="displayData.BusinessOwnerNumber"> +{{displayData.BusinessOwnerNumber}}</v-list-item-subtitle> -->

                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <div style="background: #1976d2;color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                            {{displayData.Status==1 ? 'Active' : "De-Active"}}
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-flex> 
                        <v-flex xs12 sm6 md6>
                            <v-card flat margin-left-ten style="height: 100%;">
                                <v-subheader>Address</v-subheader>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <span v-if="displayData.Address">{{displayData.Address}}</span>
                                </v-card-text>
                            </v-card>
                        </v-flex>

                        <v-flex xs12 sm6 md6>
                            <v-list style="height: 100%;" nopadding three-line margin-top-ten>
                                <v-subheader>GST Details</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item style="padding-right:0">
                                    <v-list-item-content class="pt-2 pb-2">
                                    <v-list-item-title>
                                        {{displayData.GSTRegistrationStatus == 1 ? "Registered Business" : displayData.GSTRegistrationStatus == 2 ? "Composite Business" : "Unregistered Business"}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>GST Number: {{displayData.GSTNumber}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{displayData.HSNApplicable ? "HSN Applicable" : "HSN Not Applicable"}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Tax Mode: {{displayData.TaxMode == 1 ? 'Exclusive' : displayData.TaxMode == 2 ? 'Inclusive' : 'None'}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-flex> 
                        <v-flex xs12 sm6 md6>
                            <v-list three-line style="height: 100%;" full-width margin-left-ten margin-top-ten>
                                <v-list-item style="padding-right:0">
                                    <v-list-item-content class="pt-2 pb-2">
                                    <v-list-item-title>Created On</v-list-item-title>
                                    <v-list-item-subtitle>{{displayData.CreatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{displayData.IsSupport ? "By Support User" : "By Customer"}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-flex>

                        <v-flex xs12 sm6 md6 margin-top-ten>
                            <v-list three-line margin-top-ten style="height: 100%;">
                                <v-list-item style="padding-right:0">
                                    <v-list-item-content class="pt-2 pb-2">
                                    <!-- <v-list-item-subtitle v-if="displayData.GSTRegistrationStatus">Registration Type: {{fn_getRegistrationType(displayData.GSTRegistrationStatus)}}</v-list-item-subtitle> -->
                                    <v-list-item-subtitle v-if="displayData.IsGSTINFilled != null">Is GST Filled: {{displayData.IsGSTINFilled == true ? 'Yes' : displayData.IsGSTINFilled == false ? 'No' : '' }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="displayData.IsAddressFilled != null">Is Address Filled: {{displayData.IsAddressFilled == true ? 'Yes' : displayData.IsAddressFilled == false ? 'No' : '' }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="displayData.IsPoweredByRemoved != null">Is Powered By Removed: {{displayData.IsPoweredByRemoved == true ? 'Yes' : displayData.IsPoweredByRemoved == false ? 'No' : '' }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="displayData.IsDriveSetup != null">Is Drive Setup: {{displayData.IsDriveSetup == true ? 'Yes' : displayData.IsDriveSetup == false ? 'No' : '' }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="displayData.Tags && displayData.Tags.length>0">
                                        Tags: 
                                        <span v-for="(tag, index2) in displayData.Tags" :key="index2">
                                            <span>{{tag}}<span v-if="displayData.Tags.length - 1 != index2">, </span></span>
                                        </span>
                                    </v-list-item-subtitle>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-flex> 

                    </v-layout>




                    </div>
            </v-card>
        </v-dialog>

        <dialog-business-metrics ref="ref_dialogBusinessMetrics" :hideOverlay="fn_hideOverlay"></dialog-business-metrics>
        <dialog-businessdetail-metrics ref="ref_dialogBusinessDetailMetrics"></dialog-businessdetail-metrics>
        <dialog-addtags ref="ref_dialogAddTags" :fn_updateTags="fn_updateReload"></dialog-addtags>

        <v-dialog v-model="loadingdialog" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_confirmation" persistent max-width="500" transition="slide-y-transition">
           <v-card>
                <v-card-title class="title noshadow">Confirmation</v-card-title>
                <v-card-text class="pt-0">
                    <p class="mb-2">Are you sure you want to activate below business:</p>
                    <p class="mb-1">Business Name: <b v-if="displayData">{{ displayData ? displayData.BusinessName : '' }}</b></p>
                    <p class="mb-1">Business ID: <b v-if="displayData">{{ displayData ? displayData.BusinessID : '' }}</b></p>
                    <p class="mb-1">Phone Number: <b v-if="displayData">{{ displayData && (displayData.BusinessProfileNumber ? displayData.BusinessProfileNumber : displayData.BusinessOwnerNumber) }}</b></p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="padding:6px 0px;">
                    <v-layout class="ma-0" row wrap>
                        <v-flex xs6 sm6 md6 center-align style="border-right:1px #ddd solid">
                            <v-btn small font-heavy capitalize dark-opacity-color text @click.native="closeConfirmationDialog">No</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6 center-align>
                            <v-btn small font-heavy capitalize class="primary-color" text @click.native="fn_activeBusinees">Yes</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_activeconfirmation" persistent max-width="500" transition="slide-y-transition">
           <v-card>
                <v-card-title class="title noshadow">Business Activation Successful</v-card-title>
                <v-card-text class="pt-0">
                    <p class="mb-2"><b v-if="displayData">{{ displayData ? displayData.BusinessName : '' }}</b> business has been activated successfully!</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="padding:6px 0px;">
                    <v-layout class="ma-0" row wrap>
                        <v-flex xs6 sm6 md6 center-align>
                            <v-btn small font-heavy capitalize class="primary-color" text @click.native="closeActiveConfirmationDialog">Close</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_error" persistent max-width="400" transition="slide-y-transition">
           <v-card>
                <v-card-title class="title noshadow">Error</v-card-title>
                <v-card-text class="pt-0" v-html="errorMessage">
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="padding:6px 0px;">
                    <v-spacer></v-spacer>
                    <v-btn small font-heavy capitalize class="primary-color mr-4" text @click.native="dialog_error = false">Close</v-btn>    
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>     

<script>

import axios from 'axios';

import dialogBusinessMetrics from '../../tickets/dialogs/dialog_viewbusinessmetricsfullscreen';
import dialogBusinessDetailMetrics from '../../tickets/dialogs/dialog_viewbusinessdetailmetricsfullscreen';
import dialogAddTags from '../../tickets/dialogs/dialog_addtags';

export default{
    props:['attachments', 'downloadAttachment', 'type', 'showBusinessMetrics', 'updateData'],
    data(){ 
        return{
            dialog_businessdetails: false,
            loadingdialog: false,
            loadingObj:{
                loading:false,
                message:"Please wait..."    
            },
            displayData:null,
            isHideOverlay: true,
            RegistrationType:[
                {"name":"All", "status":null},
                {"name":"Registered", "status":1},
                {"name":"Composite", "status":2},
                {"name":"Unregistered", "status":3}
            ],
            businessID: null,
            dialog_confirmation: false,
            dialog_activeconfirmation: false,
            errorMessage: null,
            dialog_error: false,
        }
    },
    components: {
        'dialog-business-metrics':dialogBusinessMetrics,
        'dialog-businessdetail-metrics': dialogBusinessDetailMetrics,
        'dialog-addtags': dialogAddTags
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_businessdetails = true;
        },
        closeDialogWeb: function () {
            this.dialog_businessdetails = false;
            var wrp = document.getElementsByTagName('html');
            for (var i = 0; i < wrp.length; i++) {
                wrp[i].style.height = "auto";
                wrp[i].style.overflowY = "auto";
            }
        },
        fn_getRegistrationType: function(value){
            for(var i in this.RegistrationType){
                if(this.RegistrationType[i].status == value)
                    return this.RegistrationType[i].name
            }
        },

        fn_toggleMobileHideShow: function(){
                let displayPropertyVal = document.getElementsByClassName('label_mobile_number')[0].style.display;
                if(displayPropertyVal == 'none'){
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'inline-block';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'Hide';
                }
                else{
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'none';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'View';
                }
            },

        fn_showBusinessMetrics: function(businessId){
            //this.isHideOverlay = false;
            this.$refs['ref_dialogBusinessMetrics'].fn_getToken(businessId);
        },

        fn_showAddTags: function(businessId){
            this.$refs['ref_dialogAddTags'].fn_getToken(businessId);
        },

        fn_showEditTags: function(businessId, tags){
            this.$refs['ref_dialogAddTags'].fn_getToken(businessId, tags);
        },

        fn_showBusinessDetailMetrics: function(businessId){
            this.$refs['ref_dialogBusinessDetailMetrics'].fn_getToken(businessId);
        },

        fn_getToken: function(businessid, hideloading){
                let self = this;
                this.businessID = businessid;
                if(!hideloading)
                    this.loadingdialog = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.loadingdialog = false;
                        return;
                    }

                    self.fn_getBusiness(token, businessid);
                });
            },

            fn_getBusiness: function(token, businessid){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesses/'+businessid, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.loadingdialog = false;
                    this.dialog_businessdetails = true;
                    var wrp = document.getElementsByTagName('html');
                    for (var i = 0; i < wrp.length; i++) {
                        wrp[i].style.height = "100vh";
                        wrp[i].style.overflowY = "hidden";
                    }
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    this.displayData = response.data;
                    setTimeout(function(){
                        document.getElementsByClassName('label_mobile_number')[0].style.display = 'none';
                        document.getElementsByClassName('label_hide_show')[0].innerHTML = 'View';
                    }, 100);
                   
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

            fn_hideOverlay: function(){
                this.isHideOverlay = true;
            },

            fn_updateReload: function(updatedtags, updatedtagids){
                console.log(updatedtags);
                this.displayData.Tags = updatedtags;
                this.displayData.TagIDs = updatedtagids;
                //this.fn_getToken(this.displayData.BusinessID, true);
                this.updateData(this.displayData.BusinessID, this.displayData.Tags);
            },

            fn_showActiveBusinessConfirmation: function(){
                this.dialog_confirmation = true;
            },

            closeConfirmationDialog: function(){
                this.dialog_confirmation = false;
                // var wrp = document.getElementsByTagName('html');
                // for (var i = 0; i < wrp.length; i++) {
                //     wrp[i].style.height = "auto";
                //     wrp[i].style.overflowY = "auto";
                // }
            },

            closeActiveConfirmationDialog: function(){
                this.dialog_activeconfirmation = false;
            },

            fn_activeBusinees: function(){

                let self = this;
               
                this.loadingdialog = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.loadingdialog = false;
                        return;
                    }

                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };
                        let phoneNumber = self.displayData.BusinessProfileNumber ? self.displayData.BusinessProfileNumber : self.displayData.BusinessOwnerNumber;
                        axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesses/' + self.displayData.BusinessID  + '/' + phoneNumber + '/activate', headersObj, {timeout:30000})
                        .then(response => {
                            self.$root.fn_hideLoading(self.loadingObj);
                            console.log(response);
                            self.dialog_confirmation = false;
                            self.dialog_activeconfirmation = true;
                            self.loadingdialog = false;
                            self.fn_getToken(self.displayData.BusinessID);
                            self.updateData();
                        }).catch(e => {
                            self.$root.fn_hideLoading(self.loadingObj);
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            self.snackbarRetry = true;
                            self.loadingdialog = false;
                            if(e.response && e.response.data != undefined){
                                self.dialog_confirmation = false;
                                self.errorMessage = e.response.data.Message;
                                self.dialog_error = true;
                            }
                            console.log(JSON.stringify(e.response.data));
                        })
                });

            },

    }
}
</script>

<style>
.businessview_fullscreendialog{
    position: absolute;
    right: 0px;
    left: auto;
    max-width: 100% !important;
    width: calc(100% - 256px);
    max-height: 100% !important;
    margin: 0px;
    height: calc(100% - 56px);
    top: 56px;
    box-shadow: none !important;
}

.toolbar_rightalign_btn{
    position: absolute;
    right: 285px;
}
</style>